import { z } from "zod";
import errorMessages from "./errorMessages";
import { frenchPhoneNumberRegex } from "../utils/numbers";
export const informationCompanySchema = z.object({
  nom: z.string({
    required_error: errorMessages.required,
  }),
  prenom: z.string({
    required_error: errorMessages.required,
  }),
  telephone: z
  .string({
    required_error: errorMessages.required,
  })
  .refine((value) => Number(value[0]) === 0, {
    message: errorMessages.invalidPhoneNumberFirtChiffre,
  })
  .refine((value) => Number(value[1]) >= 1, {
    message: errorMessages.invalidPhoneNumberSecondChiffre,
  })
  .refine((value) => frenchPhoneNumberRegex.test(value), {
    message: errorMessages.invalidPhoneNumber,
  }),
  email: z
    .string({
      required_error: errorMessages.required,
    })
    .email({ message: errorMessages.email }),
  nom_structure: z.string({
    required_error: errorMessages.required,
  }),
  code_postal: z
    .string({
      required_error: errorMessages.required,
    })
    .min(5, { message: errorMessages.codePostal })
    .max(5, { message: errorMessages.codePostal }),
  numeroRue: z.string({
    required_error: errorMessages.required,
  }),
  rue: z.string({
    required_error: errorMessages.required,
  }),
  ville: z.string({
    required_error: errorMessages.required,
  }),
  pays: z.string({
    required_error: errorMessages.required,
  }),
});
