import { CheckIcon } from "../../assets/svgs";
import { freeTrial } from "../../static";
const OfferDetailsTry = ({ commande }) => {



  return (
    <div className="w-full my-2">
      <h1>Récapitulatif </h1>
      <ul className="flex flex-column gap-3 my-4 text-xs md:text-base">
        <li className="flex justify-content-between align-items-center">
          <strong className="font-semibold">
            Forfait {commande?.forfait?.nombre_heure}h{" "}
            {commande.forfait?.isAnnuel ? "annuel" : "mensuel"} :{" "}
          </strong>
          <strong className="font-semibold">
            0€ puis {commande.forfait.prix}€ HT/
            {commande.forfait?.isAnnuel ? "an" : "mois"} :{" "}
          </strong>
        </li>
        <li className="flex justify-content-between align-items-center">
          <strong className="font-semibold">
            Numéro{" "}
            {["standard"].includes(commande.type_cle_api.type_key)
              ? "STANDARD"
              : ["argent_doublette", "argent_triplette"].includes(
                  commande.type_cle_api.type_key
                )
              ? "ARGENT"
              : "OR"}
          </strong>
          <strong className="font-semibold">
            {commande.type_cle_api.prix_type}€ HT
          </strong>
        </li>
        {freeTrial.map((item, index) => (
          <li key={index} className="flex justify-content-between">
            <span>{item}</span>
            <span>
              <CheckIcon fill="#16C498" />
            </span>
          </li>
        ))}
      </ul>
      <div className="flex justify-content-between text-xl md:text-2xl">
        <span>Total à payer aujoudhui :</span> <span>0€</span>
      </div>
    </div>
  );
};

export default OfferDetailsTry;
