import React from "react";
import TableauResponsive from "../composants/page-tarifs/TableauResponsive";
import Header from "../composants/page-tarifs/Header";
import HeaderResponsive from "../composants/page-tarifs/HeaderResponsive";
import { useMediaQuery } from "react-responsive";
import useOffers from "../hooks/useOffers";

function Tarifs() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { data: offers, isLoading } = useOffers();
  if (isLoading) return null;
  return (
      <div style={{ margin: "0" }}>
        <div className=""></div>
        {isMobile ? (
          <HeaderResponsive offers={offers} />
        ) : (
          <Header offers={offers} />
        )}
        {isMobile ? <TableauResponsive /> : <div></div>}
        <br />
      </div>
  );
}

export default Tarifs;
