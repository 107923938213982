import React from "react";
import Logo from "../assets/images/logo/newLogo.svg";
import "../assets/css/Header.css";

function Header() {
  return (
    <>
      <header className="header-container">
        <img src={Logo} alt="standard en ligne logo" />
        <div className="connexion">
          Vous avez déjà un compte ?{" "}
          <a
            href="https://www.monstandardenligne.com/"
            style={{ textDecoration: "underline" }}
          >
            Connectez-vous
          </a>
        </div>
      </header>
    </>
  );
}

export default Header;