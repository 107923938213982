import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../../assets/css/Config/Finish/Finish.scss";

import logo from "../../assets/logo/logo.svg";
import tada from "../../assets/images/config/Finish/Finish.svg";

import checkInfos from "../../helpers/config/finish/checkInfos";
import sendEmailFinish from "../../helpers/config/finish/email";
import routes from "../../router/routes";

const ConfigFinish = () => {
  const navigate = useNavigate();

  useEffect(() => {
    loadInfo();

    // eslint-disable-next-line
  }, []);

  const loadInfo = async () => {
    const checkInfo = await checkInfos();
    console.log(checkInfo);

    if (checkInfo === "envoyer") {
      return;
    }

    if (checkInfo === false) {
      //return navigate(routes.error);
    }

    const sendConfig = await sendEmailFinish(checkInfo.data);

    if (sendConfig === false) {
      return navigate(routes.error);
    }

  };

  return (
    <div className="finish-config1">
      <div className="finish-config" id="displayStripe">
        <div className="finish-config-img">
          <img
            style={{ marginLeft: "25%", marginRight: "25%" }}
            width="50%"
            height="auto"
            src={logo}
            alt="logo-colortelecom"
          />
        </div>
        <div className="finish-config-title flexed">
          <img src={tada} alt="checked-colortelecom" />
          <p>Félicitations !</p>
        </div>
        <div className="finish-config-subtitle">
          <p>Votre formulaire à bien été envoyé !</p>
        </div>
        <div className="finish-config-text">
          <p>
            Notre équipe technique se charge de tout mettre en oeuvre pour créer
            votre standard virtuel dans l'heure (jours ouvrés).
          </p>
        </div>
        <div className="finish-config-text">
          <p>
            Vous recevrez bientôt des identifiants pour vous connecter à votre
            espace où vous pourrez consulter vos informations personnelles ainsi
            que vos statistiques
          </p>
        </div>
        <a
          className="finish-config-btn"
          href="https://www.standardenligne.fr/"
          target="_blank"
          rel="noreferrer"
        >
          <span>Retourner sur standardenligne.fr</span>
        </a>
      </div>
    </div>
  );
};

export default ConfigFinish;
