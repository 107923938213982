import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../utils/Store";

import '../../../assets/css/Config/Step/Menu.css'

import non_picto from '../../../assets/images/config/Menu/non.svg'
import oui_picto from '../../../assets/images/config/Menu/oui.svg'
import horaires_picto from '../../../assets/images/config/Menu/saisie d\'horaires.svg'
import h24_picto from '../../../assets/images/config/Menu/saisir_horraire_24h.svg'

import non_picto_active from '../../../assets/images/config/Menu/non_active.svg'
import oui_picto_active from '../../../assets/images/config/Menu/oui_active.svg'
import horaires_picto_active from '../../../assets/images/config/Menu/saisir_horraire_active.svg'
import h24_picto_active from '../../../assets/images/config/Menu/saisir_horraire_24h_active.svg'

import MenuHours from "./MenuHours";
import MenuHours2 from "./MenuHours2";
import checkEmail from "../../../helpers/checkEmail";

const MenuContainer = () => {

    const { nextStep } = useContext(StoreContext);

    const[secondPicto, setSecondPicto] = useState(0)
    const[thirdPicto, setThirdPicto] = useState(0)
    const[fourPicto, setFourPicto] = useState(0)

    const[inputMail, setInputMail] = useState([{ email: '' }])

    const[redac, setRedac] = useState({ 
        accueil: `Bonjour, vous êtes bien en communication avec la société ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}. Veuillez ne pas quitter, nous allons prendre votre appel dans quelques instants.`, 
        attente: `Toute l'équipe de ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}, vous souhaite la bienvenue et s'efforce d'écourter agréablement votre attente. Merci de rester en ligne.`, 
        fermeture: `Bonjour et bienvenue chez ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}, nous sommes ouverts du !! A COMPLETER !!, de !! A COMPLETER !!. Vous pouvez nous laisser un message, et nous vous rappellerons dès que possible, merci et à bientôt.`
    })


    let redacAccueilTemplate = [
        `Bonjour, vous êtes bien en communication avec la société ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}. Veuillez ne pas quitter, nous allons prendre votre appel dans quelques instants.`,
        `${sessionStorage.getItem('nom_societe') || 'Color Telecom'} vous remercie de votre appel et vous invite à patienter quelques instants.`,
        `Vous êtes en communication avec ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}, Nous allons donner suite à votre appel. Merci de ne pas quitter.`
    ]

    let redacAttenteTemplate = [
        `Toute l'équipe de ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}, vous souhaite la bienvenue et s'efforce d'écourter agréablement votre attente. Merci de rester en ligne.`,
        `Vous êtes en communication avec ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}, merci de patienter quelques instants.`,
        `Bienvenue chez ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}, nous recherchons votre correspondant, merci de bien vouloir patienter quelques instants.`
    ]

    let redacFermetureTemplate = [
        `Bonjour et bienvenue chez ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}, nous sommes ouverts du !! A COMPLETER !!, de !! A COMPLETER !!. Vous pouvez nous laisser un message, et nous vous rappellerons dès que possible, merci et à bientôt.`,
        `Bonjour, vous êtes bien chez ${sessionStorage.getItem('nom_societe') || 'Color Telecom'} - nous sommes actuellement fermés, vous pouvez nous recontacter pendant nos horaires d'ouverture: du !! A COMPLETER !!, de !! A COMPLETER !!. A bientôt !`,
        `Bonjour et bienvenue chez ${sessionStorage.getItem('nom_societe') || 'Color Telecom'}. Notre standard est fermé, ouverture du !! A COMPLETER !!, de !! A COMPLETER !!. Nous vous invitons à nous recontacter ultérieurement ou à nous envoyer un email à l'adresse ${sessionStorage.getItem('email_contact') || 'color@telecom.com'}. Merci, aurevoir !`
    ]

    const newRedacAcc = (val) => {
        if(redacAccueilTemplate[val] === redac.accueil) {
            return
        } else {
            setRedac({ ...redac, accueil: redacAccueilTemplate[val] })
        }
    }

    const newRedacAtt = (val) => {
        if(redacAttenteTemplate[val] === redac.attente) {
            return
        } else {
            setRedac({ ...redac, attente: redacAttenteTemplate[val] })
        }
    }

    const newRedacF = (val) => {
        if(redacFermetureTemplate[val] === redac.fermeture) {
            return
        } else {
            setRedac({ ...redac, fermeture: redacFermetureTemplate[val] })
        }
    }

    let secondPicto1 = horaires_picto;
    let secondPicto2 = h24_picto;

    switch(secondPicto) {
        case(1): {
            secondPicto1 = horaires_picto_active;
            break;
        }
        case(2): {
            secondPicto2 = h24_picto_active;
            break;
        }
        default: {
            break;
        }
    } 

    let thirdPicto1 = non_picto;
    let thirdPicto2 = oui_picto;

    switch(thirdPicto) {
        case(1): {
            thirdPicto1 = non_picto_active;
            break;
        }
        case(2): {
            thirdPicto2 = oui_picto_active;
            break;
        }
        default: {
            break;
        }
    } 

    let fourPicto1 = non_picto;
    let fourPicto2 = oui_picto;

    switch(fourPicto) {
        case(1): {
            fourPicto1 = non_picto_active;
            break;
        }
        case(2): {
            fourPicto2 = oui_picto_active;
            break;
        }
        default: {
            break;
        }
    } 

    const mailInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputMail];
        list[index][name] = value;
        setInputMail(list);
    };

    const mailRemoveClick = index => {
        const list = [...inputMail];
        list.splice(index, 1);
        setInputMail(list);
    };

    const mailAddClick = () => {
        setInputMail([...inputMail, { email: '' }]);
    };

    useEffect(() => {

        if(redac.accueil === '' || redac.attente === '' || redac.fermeture === '' || fourPicto === 0) {
            return nextStep[1](false)
        } 
        
        if(fourPicto === 1) {
            return nextStep[1](true)
        }

        let count = 0

        inputMail.map(async(x, i) => {
            const checkMail = await checkEmail(x.email)

            if(x.email === "" || checkMail === false) {
                count = count + 1
            }

            if(i + 1 === inputMail.length) {

                if(count >= 1) {
                    return nextStep[1](false)
                } else {
                    return nextStep[1](true)
                }

            }
        })
        
        
        // eslint-disable-next-line
    }, [redac, inputMail, fourPicto])

    let initRedacFermeture = <div className="menu-redac-center" style={{marginTop: '15px'}}>
                                <div className="menu-redac">
                                    <div>
                                        <p className="menu-redac-p">Rédaction du message de fermeture*</p>
                                    </div>
                                    <textarea style={{fontFamily: 'Montserrat'}} value={redac.fermeture} onChange={(val) => setRedac({ ...redac, fermeture: val.target.value})}/>
                                </div>
                                <div className="menu-redac-button-all">
                                    <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacF(0)}>Exemple 1</p>
                                    <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacF(1)}>Exemple 2</p>
                                    <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacF(2)}>Exemple 3</p>
                                </div>                                
                            </div>

    let menuHours = '';
    let redacFermeture = '';

    switch(secondPicto) {
        case(1): {
            if(thirdPicto === 2) {
                menuHours = <MenuHours/>
                redacFermeture =  initRedacFermeture
            } else if(thirdPicto === 1){
                menuHours = <MenuHours2 />
                redacFermeture = initRedacFermeture
            }
            break;
        }
        default: {
            break;
        }
    }

    useEffect(() => {
        let stringify_redac = JSON.stringify(redac)
        let stringify_picto = JSON.stringify({ picto1: secondPicto, picto2: thirdPicto, picto3: fourPicto})
        let stringify_email = JSON.stringify(inputMail)

        sessionStorage.setItem('menu_redac', stringify_redac)
        sessionStorage.setItem('menu_picto', stringify_picto)
        sessionStorage.setItem('menu_email', stringify_email)
    })

    return (
        <div className="menu-container">
            <p className="menu-title">Vous avez choisi la réalisation d’un standard avec menu</p>
            <p className="menu-subtitle">Pour faciliter votre expérience utilisateur, nous avons prérempli certains champs. Vous pouvez toujours les modifier si les textes ne vous convient pas.</p>
            <div className="menu-body">

                <div className="menu-redac-center">
                    <div className="menu-redac">
                        <div>
                            <p className="menu-redac-p">Rédaction du message d'accueil*</p>
                        </div>
                        <textarea style={{fontFamily: 'Montserrat'}} value={redac.accueil} onChange={(val) => setRedac({ ...redac, accueil: val.target.value})}/>
                        <div className="menu-redac-button-all">
                            <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacAcc(0)}>Exemple 1</p>
                            <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacAcc(1)}>Exemple 2</p>
                            <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacAcc(2)}>Exemple 3</p>
                        </div>
                    </div>

                    <div className="menu-redac">
                        <div>
                            <p className="menu-redac-p">Rédaction du message d'attente*</p>
                        </div>
                        <textarea style={{fontFamily: 'Montserrat'}} value={redac.attente} onChange={(val) => setRedac({ ...redac, attente: val.target.value})}/>
                        <div className="menu-redac-button-all">
                            <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacAtt(0)}>Exemple 1</p>
                            <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacAtt(1)}>Exemple 2</p>
                            <p className="menu-redac-button-generate" style={{userSelect: 'none'}} onClick={() => newRedacAtt(2)}>Exemple 3</p>
                        </div>
                    </div>

                </div>

                <div className="menu-picto">
                    <p>Vos horaires d'ouverture</p>
                    <div>
                        <img src={secondPicto1} alt='non-color-telecom' onClick={() => setSecondPicto(1)}/>
                        <img src={secondPicto2} alt='oui-color-telecom' onClick={() => setSecondPicto(2) | setThirdPicto(1)}/>
                    </div>
                </div>

                { secondPicto === 1 ? 
                    <div className="menu-picto">
                        <p>Etes-vous fermé pendant la pause déjeuner ?</p>
                        <div>
                            <img src={thirdPicto1} alt='non-color-telecom' onClick={() => setThirdPicto(1)}/>
                            <img src={thirdPicto2} alt='oui-color-telecom' onClick={() => setThirdPicto(2)}/>
                        </div>
                    </div>
                :
                    null
                }

                { menuHours }

                { redacFermeture }

                { secondPicto === 2 | thirdPicto === 1 | thirdPicto === 2 ?
                    <div className="menu-picto">
                        <p>Souhaitez vous que vos appelants puissent vous laisser un message à la fin du message de fermeture ?*</p>
                        <div>
                            <img src={fourPicto1} alt='non-color-telecom' onClick={() => setFourPicto(1)}/>
                            <img src={fourPicto2} alt='oui-color-telecom' onClick={() => setFourPicto(2)}/>
                        </div>
                    </div>
                :
                    null
                }

                { fourPicto === 2 ? 
                   <div className="menu-email">
                        { inputMail.map((x, i) => {
                            return (
                                <div key={i} className="menu-email-body">
                                    <p className="menu-email-text">L'adresse mail {i + 1}</p>
                                    <div className="menu-email-body menu-email-mobile">
                                        <input name="email" value={x.email} onChange={(e) => mailInputChange(e, i)}/>
                                        {inputMail.length !== 1 && 
                                            <p className="menu-email-button" onClick={() => mailRemoveClick(i)}>-</p>
                                        }
                                        {inputMail.length - 1 === i && i + 2 <= 3 && 
                                            <p className="menu-email-button add" onClick={() => mailAddClick(i)}>+</p>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>                
                :
                    null
                }

            </div>
        </div>
    );
}

export default MenuContainer;