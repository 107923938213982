import { z } from "zod";

export const numberChooseSchema = z.object({
  pays: z.string(),
  zone: z.string(),
  numeroType: z.string(),
  number: z.string(),
  editNumberType: z.string().nullish(),
  forfait: z.number(),
  numType: z.string(),
});
