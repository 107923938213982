import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../utils/Store";

import "../../assets/css/Config/Choix/Choix.css";

function ChoixContainer() {
  const { nextStep } = useContext(StoreContext);

  const [number, setNumber] = useState("2");

  const [choix1, setChoix1] = useState({ intitule: "", renvoi: "" });
  const [choix2, setChoix2] = useState({ intitule: "", renvoi: "" });
  const [choix3, setChoix3] = useState({ intitule: "", renvoi: "" });

  const [numero1, setNumero1] = useState([{ num: "" }]);
  const [numero2, setNumero2] = useState([{ num: "" }]);
  const [numero3, setNumero3] = useState([{ num: "" }]);

  const numInputChange = (state, setState, e, index) => {
    const value = e.target.value?.replace(/\D/g, "");
    const list = [...state];
    list[index].num = value;
    setState(list);
  };

  const numRemoveClick = (state, setState, index) => {
    const list = [...state];
    list.splice(index, 1);
    setState(list);
  };

  const numAddClick = (state, setState) => {
    setState([...state, { num: "" }]);
  };

  const handleInputChange = (key, value) => {
    if (value === "") {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }
  };

  const multipleInputChange = (key, value) => {
    let stringify1 = JSON.stringify(value);
    handleInputChange(key, stringify1);
  };

  useEffect(() => {
    let stringify1 = JSON.stringify(choix1);
    let stringify2 = JSON.stringify(choix2);
    let stringify3 = JSON.stringify(choix3);

    sessionStorage.setItem("config_choix_number", number);
    sessionStorage.setItem("config_choix_1", stringify1);

    if (number === "2") {
      sessionStorage.setItem("config_choix_2", stringify2);
    } else if (number === "3") {
      sessionStorage.setItem("config_choix_2", stringify2);
      sessionStorage.setItem("config_choix_3", stringify3);
    }

    // if(choix1.intitule === '' || choix1.renvoi === '' || numero1[0].num === '' || choix2.intitule === '' || choix2.renvoi === '' || numero2[0].num === '') {
    if (
      choix1.intitule === "" ||
      choix1.renvoi === "" ||
      choix2.intitule === "" ||
      choix2.renvoi === ""
    ) {
      nextStep[1](false);
    } else if (choix3.intitule === "" || choix3.renvoi === "") {
      // } else if(choix3.intitule === '' || choix3.renvoi === '' || numero3[0].num === '') {

      if (number === "2") {
        nextStep[1](true);
      } else {
        nextStep[1](false);
      }
    } else {
      nextStep[1](true);
    }
  });

  return (
    <div className="choix-container">
      <p className="choix-title">
        Vous avez choisi la réalisation d'un standard avec menu
      </p>
      <p className="choix-subtitle">
        Indiquez le nombre de services que vous souhaitez mettre en place ainsi
        que leurs numéros de renvoi.
      </p>
      <div className="choix-body">
        <div className="choix-input-select">
          <select onChange={(val) => setNumber(val.target.value)}>
            <option value="2">Nombre de service : 2</option>
            <option value="3">Nombre de service : 3</option>
          </select>
        </div>
        <div className="choix-list">
          <div className="choix-list-body">
            <input
              placeholder="Intitulé De Votre Choix 1*"
              onChange={(val) =>
                setChoix1({ ...choix1, intitule: val.target.value })
              }
            ></input>
            <input
              maxLength={10}
              minLength={10}
              placeholder="Renvoi vers Numéro 1"
              value={choix1.renvoi}
              onChange={(val) =>
                setChoix1({
                  ...choix1,
                  renvoi: val.target.value.replace(/\D/g, ""),
                })
              }
            ></input>
            {numero1.map((x, i) => {
              return (
                <div key={i} className="choix-num-list">
                  <input
                    maxLength={10}
                    minLength={10}
                    placeholder={`Si Numéro ${i + 1} Est Injoingnable`}
                    value={x.num}
                    onChange={(e) =>
                      numInputChange(numero1, setNumero1, e, i) |
                      multipleInputChange("choix_numero_1", numero1)
                    }
                  ></input>
                  {numero1.length !== 1 && (
                    <p
                      className="choix-num-button"
                      onClick={() => numRemoveClick(numero1, setNumero1, i)}
                    >
                      -
                    </p>
                  )}
                  {numero1.length - 1 === i && i + 1 <= 3 && (
                    <p
                      className="choix-num-button add"
                      onClick={() => numAddClick(numero1, setNumero1)}
                    >
                      +
                    </p>
                  )}
                </div>
              );
            })}
          </div>

          <div className="choix-list-body">
            <input
              placeholder="Intitulé De Votre Choix 2*"
              onChange={(val) =>
                setChoix2({ ...choix2, intitule: val.target.value })
              }
            ></input>
            <input
              maxLength={10}
              minLength={10}
              placeholder="Renvoi vers Numéro 2"
              value={choix2.renvoi}
              onChange={(val) =>
                setChoix2({
                  ...choix2,
                  renvoi: val.target.value.replace(/\D/g, ""),
                })
              }
            ></input>
            {numero2.map((x, i) => {
              return (
                <div key={i} className="choix-num-list">
                  <input
                    maxLength={10}
                    minLength={10}
                    placeholder={`Si Numéro ${i + 2} Est Injoingnable`}
                    value={x.num}
                    onChange={(e) =>
                      numInputChange(numero2, setNumero2, e, i) |
                      multipleInputChange("choix_numero_2", numero2)
                    }
                  ></input>
                  {numero2.length !== 1 && (
                    <p
                      className="choix-num-button"
                      onClick={() => numRemoveClick(numero2, setNumero2, i)}
                    >
                      -
                    </p>
                  )}
                  {numero2.length - 1 === i && i + 1 <= 3 && (
                    <p
                      className="choix-num-button add"
                      onClick={() => numAddClick(numero2, setNumero2)}
                    >
                      +
                    </p>
                  )}
                </div>
              );
            })}
          </div>

          {number >= 3 ? (
            <div className="choix-list-body">
              <input
                placeholder="Intitulé De Votre Choix 3*"
                onChange={(val) =>
                  setChoix3({ ...choix3, intitule: val.target.value })
                }
              ></input>
              <input
                maxLength={10}
                minLength={10}
                placeholder="Renvoi vers Numéro 3"
                value={choix3.renvoi}
                onChange={(val) =>
                  setChoix3({
                    ...choix3,
                    renvoi: val.target.value.replace(/\D/g, ""),
                  })
                }
              ></input>
              {numero3.map((x, i) => {
                return (
                  <div key={i} className="choix-num-list">
                    <input
                      maxLength={10}
                      minLength={10}
                      placeholder={`Si Numéro ${i + 3} Est Injoingnable`}
                      value={x.num}
                      onChange={(e) =>
                        numInputChange(numero3, setNumero3, e, i) |
                        multipleInputChange("choix_numero_3", numero3)
                      }
                    ></input>
                    {numero3.length !== 1 && (
                      <p
                        className="choix-num-button"
                        onClick={() => numRemoveClick(numero3, setNumero3, i)}
                      >
                        -
                      </p>
                    )}
                    {numero3.length - 1 === i && i + 1 <= 3 && (
                      <p
                        className="choix-num-button add"
                        onClick={() => numAddClick(numero3, setNumero3)}
                      >
                        +
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ChoixContainer;
