import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../utils/Store";

import "../../assets/css/Config/Step/Renvoi.css";

import picto_info from "../../assets/images/config/interrogation.svg";
import { InputMask } from "primereact/inputmask";

const RenvoiContainer = () => {
  const { nextStep } = useContext(StoreContext);

  const [popup, setPopup] = useState(false);

  const handleInputChange = (key, value) => {
    if (value === "") {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }
  };

  const checkTel = (val) => {
    if (val.length < 10 || isNaN(val)) {
      return false;
    } else if (val.length >= 10) {
      return true;
    } else {
      return true;
      // sessionStorage.removeItem("numero_renvoi");
    }
  };

  const [numero1, setNumero1] = useState([{ num: "" }]);

  useEffect(() => {
    let count = 0;

    // eslint-disable-next-line array-callback-return
    numero1.map((x) => {
      if (checkTel(x.num) === false) {
        count = count + 1;
      }
    });

    if (count === 0) {
      nextStep[1](true);
      sessionStorage.setItem("numero_renvoi", JSON.stringify(numero1));
    } else {
      nextStep[1](false);
      sessionStorage.removeItem("numero_renvoi");
    }

    // eslint-disable-next-line
  }, [numero1]);

  const numInputChange = (state, setState, e, index) => {
    const value = e.target.value?.replace(/\D/g, "");
    const list = [...state];
    list[index].num = value;
    setState(list);
  };

  const numRemoveClick = (state, setState, index) => {
    const list = [...state];
    list.splice(index, 1);
    setState(list);
  };

  const numAddClick = (state, setState) => {
    setState([...state, { num: "" }]);
  };

  const multipleInputChange = (key, value) => {
    const stringify1 = JSON.stringify(value);
    handleInputChange(key, stringify1);
  };

  return (
    <div className="renvoi">
      <p className="renvoi-title">
        Vous avez choisi la réalisation d'un standard avec menu
      </p>
      <div className="renvoi-body">
        <div className="renvoi-text">
          <p className="renvoi-text1">
            Merci de nous communiquer le numéro de renvoi*
          </p>
          <div style={{ position: "relative" }}>
            <p
              className="renvoi-text2"
              onMouseEnter={() => setPopup(true)}
              onMouseLeave={() => setPopup(false)}
            >
              <img
                style={{ position: "relative", top: "1px" }}
                src={picto_info}
                alt="picto_info_colortelecom"
                width="20px"
                height="auto"
              />{" "}
              Aide
            </p>
            {popup ? (
              <p
                onMouseEnter={() => setPopup(true)}
                onMouseLeave={() => setPopup(false)}
                style={{
                  position: "absolute",
                  right: "10px",
                  border: "1px solid #264A6E",
                  backgroundColor: "#FFFFFF",
                  padding: "10px",
                  minWidth: "25vh",
                  width: "auto",
                  maxWidth: "75vh",
                }}
              >
                Merci d'indiquer le numéro de téléphone qui recevra les appels.
              </p>
            ) : null}
          </div>
        </div>

        {numero1.map((x, i) => {
          return (
            <div key={i} className="choix-num-list">
              <input
                type="text"
                name="phone"
                required
                minLength="10"
                maxLength="10"
                placeholder={`ex: 06 51 24 67 68`}
                value={x.num}
                onChange={(e) =>
                  numInputChange(numero1, setNumero1, e, i) |
                  multipleInputChange("choix_numero_1", numero1)
                }
              ></input>
              {numero1.length !== 1 && (
                <p
                  className="choix-num-button"
                  onClick={() => numRemoveClick(numero1, setNumero1, i)}
                >
                  -
                </p>
              )}
              {numero1.length - 1 === i && i + 1 <= 2 && (
                <p
                  className="choix-num-button add"
                  onClick={() => numAddClick(numero1, setNumero1)}
                >
                  +
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RenvoiContainer;
