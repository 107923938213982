import { useStripe, useElements } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import MainButton from "../inscription/MainButton";
import { toast } from "react-toastify";
import Secure3D from "../../assets/images/3d-secure.png";

import {
  stripeAttachPaymentMethod,
  createStripeSubscription,
} from "../../services/stripe";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import routes from "../../router/routes";

export default function CheckoutForm({ commande, prixForfait, clientSecret }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const methods = useForm();

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return null;
    }
    
    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (error) {
      toast.error(error.message, {
        position: "bottom-right",
      });
      return null;
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    switch (paymentIntent.status) {
      case "requires_capture":
        try {
          const attachResponse = await stripeAttachPaymentMethod({
            commande,
          });
          if (!Boolean(attachResponse.success)) return false;

          const createSubscriptionResponse = await createStripeSubscription({
            commande,
          });
          if (createSubscriptionResponse?.success === true) 
            navigate(`${routes.successCheckout}?commande=${commande}`);
          break;
        } catch (error) {
          toast.error(error?.message, {
            position: "bottom-right",
          });
          break;
        }

      case "processing":
        /* setMessage(
          "Processing payment details. We'll update you when processing is complete."
        ); */
        break;

      case "requires_payment_method":
        // Redirect your user back to your payment page to attempt collecting
        // payment again
        toast.error(paymentIntent.last_setup_error.message, {
          position: "bottom-right",
        });
        break;

      default:
        break;
    }
  };
  const ButtonConversion = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-1000263363/22r7CKyKpPkYEMOd-9wD',
        'value': prixForfait,
        'currency': 'EUR',
        'transaction_id': commande
      });
    }
  };


  return (
    <form onSubmit={methods.handleSubmit(handleSubmit)} className="mt-4">
      <div className="border-1 border-300	p-3 my-2">
        <CardSection />
      </div>
      <div className="w-full  flex align-items-center my-3">
        <span className="font-medium text-md font-italic	">
          Protocole sécurisé par le système 3D Secure{" "}
        </span>
        <span className="mx-4 flex align-items-center">
          <img src={Secure3D} width={25} height={28} alt="3D secure payment" />
        </span>
      </div>
      <div className="flex relative w-full align-items-center justify-content-center lg:justify-content-end mt-6 mb-3">
        <MainButton
          label="Configurer mon standard"
          disabled={methods.formState.isSubmitting || !stripe}
          onClick={ButtonConversion}
        />
      </div>
    </form>
  );
}
