import { useState } from "react";
import "../assets/css/ProgressBar.css";

const ProgressBar = (props) => {
  const [cWidth, setWidth] = useState(props.before);
  const [secure, setSecure] = useState(false);
  // const [screen, setScreen] = useState(window.innerWidth);

  window.addEventListener("resize", function () {
    setWidth(props.percentage);
    // setScreen(window.innerWidth);
  });

  let WIDTH = 0;
  let WIDTH2 = 0;

  // if (screen <= 850) {
  //   WIDTH2 = props.percentage;
  // } else {
  WIDTH = props.percentage * 100;
  WIDTH2 = WIDTH / 100;
  WIDTH = WIDTH2 + "%";
  // }

  const addWith = () => {
    setWidth(WIDTH2);
  };

  if (secure === false) {
    setSecure(true);
    setTimeout(addWith, 100);
  }

  return (
    <div className="progressbar">
      <div className="progress-head">
        <div
          className="progress-fill"
          style={{ width: cWidth + "%", transitionDuration: "2s" }}
        >
          <span className="progress-text">{props.percentage}%</span>
        </div>
      </div>
      <span className="progress-step">Étape {props.etape}</span>
    </div>
  );
}

export default ProgressBar;
