import france from "../../assets/images/flag/france.png";
const valueTemplate = (option) => {
  return (
    <div className="flex align-items-center justify-content-between">
      <span>{option?.label ?? "Choisissez la zone"}</span>
      {option?.value && (
        <span className="flex align-items-center font-semibold">
          <img
            width={30}
            height={30}
            className="france-flag"
            src={france}
            alt="France flag"
            style={{ marginRight: "7px", marginLeft: "7px" }}
          />
          {option.value}
        </span>
      )}
    </div>
  );
};

const optionTemplate = (option) => {
  if (option?.isOffert === true) {
    return (
      <>
        <span>{option.label}</span>
        <span className="secondary-bg py-1 px-2 border-round light-primary-text ml-1 free-numbers">OFFERT</span>
      </>
    );
  }
  if (option?.isInclude === true) {
    return (
      <>
        <span>{option.label}</span>
        <span> (inclus)</span>
      </>
    );
  }
  return <span>{option.label}</span>;
};

const selectedCountryTemplate = (option, props) => {
  if (option) {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.label}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "24px" }}
        />
        <div>{option.label}</div>
      </div>
    );
  }

  return <span>{props.placeholder}</span>;
};

const countryOptionTemplate = (option) => {
  return (
    <div className="flex align-items-center">
      <img
        alt={option.label}
        src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
        className={`mr-2 flag flag-${option.code.toLowerCase()}`}
        style={{ width: "24px" }}
      />
      <div>{option.label}</div>
    </div>
  );
};

const groupedItemTemplate = (option) => {
  if (option?.prix) console.log("option price", option);
  return (
    <div className="flex align-items-center">
      <div className="primary-bg p-2 border-round light-primary-text w-full text-center font-semibold">
        {option.label}
      </div>
    </div>
  );
};

const forfaitValueTemplate = (option) => {
  return (
    <div className="flex align-items-center gap-2 ml-5">
      <span
        className={
          option.nombre_heure === "Illimité"
            ? "secondary-bg p-2 border-round light-primary-text"
            : ""
        }
      >
        {option.nombre_heure === "Illimité" ? "Illimité" : option.label}
      </span>{" "}
      <span className="font-medium">{option.prix} €</span>
    </div>
  );
};

const forfaitSelectedTemplate = (option) => {
  if (option === null)
    return (
      <div className="flex align-items-center gap-2">
        <span>Choisissez votre forfait</span>
      </div>
    );
  if (option.nombre_heure === "Illimité") {
    return (
      <div className="flex align-items-center gap-2">
        <span className="secondary-bg py-1 px-2 border-round light-primary-text">
          {option.nombre_heure}
        </span>{" "}
        <span className="font-semibold">{option.prix} €</span>
      </div>
    );
  } else {
    return (
      <div className="flex align-items-center gap-2">
        <span className="primary-bg py-1 px-2 border-round light-primary-text">
          {option.label}
        </span>{" "}
        <span className="font-semibold">{option.prix} €</span>
      </div>
    );
  }
};

export {
  valueTemplate,
  optionTemplate,
  selectedCountryTemplate,
  countryOptionTemplate,
  groupedItemTemplate,
  forfaitValueTemplate,
  forfaitSelectedTemplate,
};
