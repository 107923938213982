import React from "react";
import { Outlet } from "react-router";
import Header from "../composants/Header";
import SlidePanel from "../composants/SlidePanel";
import "../assets/css/inscription/Inscritpion.css";

const Inscription = () => {
  return (
    <div className="inscription">
      <div className="inscription-header">
        <Header />
      </div>
      <div className="inscription-aside">
        <SlidePanel />
      </div>
      <main className="inscrption-conatiner">
        <h1 className="px-4 lg:px-8 mb-2 text-2xl mb-3 sm:mb-6 text sm:text-6xl">
          Démarrez votre essai gratuit de
          <strong style={{backgroundColor:'#16C498'}} className="mx-2 px-2 sm:px-3 py-1 border-round text-white white-space-nowrap">
            7 jours
          </strong>
        </h1>
        <Outlet />
      </main>
    </div>
  );
};

/* 

   
*/

export default Inscription;
