import React, { useEffect, useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { informationCompanySchema } from "../../schema/informationCompanySchema";
import Steps from "./Steps";
import InputContainer from "../containers/InputContainer";
import ControlledInput from "../inputs/ControlledInput";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { pays } from "../../static";
import {
  countryOptionTemplate,
  selectedCountryTemplate,
} from "../inputs/Templates";
import { Dropdown } from "primereact/dropdown";
import { useLocalStorage } from "primereact/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../router/routes";
import MainButton from "./MainButton";
import { onCreateClient, onCreateCommande } from "../../querys/inscription";
import { isEmailAvailable, isEntityNameAvailable } from "../../services/client";
import forbiddenPrefixes from "./forbiddenPrefixes.json";

const InformationCompany = () => {
  const [, setInformationCompany] = useLocalStorage({}, "informationCompany");
  const [, setInformationCompanyResponse] = useLocalStorage(
    {},
    "informationCompanyResponse"
  );
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email")
    ? decodeURIComponent(searchParams.get("email"))
    : "";
  const [choixNumero, _] = useLocalStorage({}, "choixNumero");
  const navigate = useNavigate();
  const methods = useForm({
    values: {
      email,
      pays: "France",
    },
    resolver: zodResolver(informationCompanySchema),
  });

  const isMobile = window.innerWidth < 768;
  const getFormErrorMessage = (name, showOnMobile = false) => {
    const {
      formState: { errors },
    } = methods;
    return errors[name] ? (
      <small className="p-error px-3">
        {showOnMobile ? "requis" : errors[name].message}
      </small>
    ) : (
      <small className="p-error px-3">&nbsp;</small>
    );
  };
  const onSubmit = async (data) => {
    try {
      if (!isAvailable.email || !isAvailable.entityName) {
        if (!isAvailable.email)
          methods.setError("email", {
            type: "value",
            message: "Email non disponible",
          });
        if (!isAvailable.entityName)
          methods.setError("nom_structure", {
            type: "value",
            message: "Nom De L'entreprise non disponible",
          });
        return;
      }
      const numero = JSON.parse(choixNumero);
      const client = await onCreateClient(data);
      const commande = await onCreateCommande({ client, numero });
      setInformationCompany(JSON.stringify(data));
      setInformationCompanyResponse(JSON.stringify(commande));
      navigate(routes.inscriptionPayment);
    } catch (error) {
      console.log(error);
    }
  };

  const [isAvailable, setIsAvailable] = useState({
    email: false,
    entityName: false,
  });

  const formEmail = useWatch({
    name: "email",
    control: methods.control,
  });

  const formEntityName = useWatch({
    name: "nom_structure",
    control: methods.control,
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (formEmail)
        isEmailAvailable({ email: formEmail })
          .then(() => {
            methods.trigger(["email"]);
            setIsAvailable({ ...isAvailable, email: true });
          })
          .catch((err) => {
            methods.setError("email", {
              type: "value",
              message: "Email non disponible",
            });
            setIsAvailable({ ...isAvailable, email: false });
          });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [formEmail]);

  const [phoneMessage, setPhoneMessage] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const formTelephone = useWatch({
    name: "telephone",
    control: methods.control,
  });

  useEffect(() => {
    const prefix = formTelephone?.replace(/\s/g, "").substring(0, 6);
    if (prefix && forbiddenPrefixes.includes(prefix)) {
      setPhoneMessage("Nous ne pouvons pas prendre en charge ce numéro de téléphone. Veuillez en saisir un autre.");
      setIsPhoneValid(false);
    } else {
      setPhoneMessage("");
      setIsPhoneValid(true);
    }
  }, [formTelephone]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (formEntityName)
        isEntityNameAvailable({ entityName: formEntityName })
          .then(() => {
            methods.trigger(["nom_structure"]);
            setIsAvailable({ ...isAvailable, entityName: true });
          })
          .catch((err) => {
            methods.setError("nom_structure", {
              type: "value",
              message: "Nom De L'entreprise non disponible",
            });
            setIsAvailable({ ...isAvailable, entityName: false });
          });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [formEntityName]);
  return (
    <FormProvider {...methods}>
      <form className="px-4 lg:px-8" onSubmit={methods.handleSubmit(onSubmit)}>
        <Steps step={2} maxStep={3} />
        <InputContainer show={true} label="Vos Informations">
          <ControlledInput name="nom" control={methods.control}>
            <InputText
              id="nom"
              type="text"
              className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.nom && "p-invalid"
                }`}
              placeholder="Nom"
            />
          </ControlledInput>
          {getFormErrorMessage("nom")}
          <ControlledInput name="prenom" control={methods.control}>
            <InputText
              id="prenom"
              type="text"
              className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.prenom && "p-invalid"
                }`}
              placeholder="Prénom"
            />
          </ControlledInput>
          {getFormErrorMessage("prenom")}
          <ControlledInput name="telephone" control={methods.control}>
            <InputMask
              id="telephone"
              type="text"
              className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.telephone && "p-invalid"
                }`}
              keyfilter={"int"}
              placeholder="Numéro De Téléphone"
              mask="99 99 99 99 99"
            />
          </ControlledInput>
          {getFormErrorMessage("telephone")}
          {phoneMessage && (
            <small className="p-error px-3">{phoneMessage}</small>
          )}
        </InputContainer>
        <InputContainer show={true} label="Votre Entreprise">
          <ControlledInput name="email" control={methods.control}>
            <InputText
              id="email"
              type="email"
              className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.email && "p-invalid"
                }`}
              placeholder="Email"
            />
          </ControlledInput>
          {getFormErrorMessage("email")}
          <ControlledInput name="nom_structure" control={methods.control}>
            <InputText
              id="nom_structure"
              type="text"
              className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.nom_structure && "p-invalid"
                }`}
              placeholder="Nom De L'entreprise"
            />
          </ControlledInput>
          {getFormErrorMessage("nom_structure")}
        </InputContainer>
        <InputContainer show={true} label="Adresse">
          <div className="flex align-items-start gap-2 md:gap-4 w-full my-2">
            <div className="flex flex-column w-4">
              <ControlledInput name="numeroRue" control={methods.control}>
                <InputText
                  id="numeroRue"
                  type="text"
                  className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.numeroRue && "p-invalid"
                    }`}
                  placeholder="N° rue"
                  keyfilter={"int"}
                />
              </ControlledInput>
              {getFormErrorMessage("numeroRue", isMobile)}
            </div>
            <div className="flex flex-column w-full">
              <ControlledInput name="rue" control={methods.control}>
                <InputText
                  id="rue"
                  type="text"
                  className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.rue && "p-invalid"
                    }`}
                  placeholder="Rue"
                />
              </ControlledInput>
              {getFormErrorMessage("rue")}
            </div>
          </div>
          <div className="flex align-items-start gap-2 md:gap-4 w-full my-2 ">
            <div className="flex flex-column w-8 md:w-4">
              <ControlledInput name="code_postal" control={methods.control}>
                <InputMask
                  id="code_postal"
                  type="text"
                  className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.code_postal && "p-invalid"
                    }`}
                  maxLength={5}
                  placeholder="Code Postal"
                  keyfilter={"int"}
                  mask="99999"
                />
              </ControlledInput>
              {getFormErrorMessage("code_postal", isMobile)}
            </div>
            <div className="flex flex-column w-full">
              <ControlledInput name="ville" control={methods.control}>
                <InputText
                  id="ville"
                  type="text"
                  className={`w-full h-3rem px-3 text-black-alpha-80 ${methods.formState.errors.ville && "p-invalid"
                    }`}
                  placeholder="Adresse"
                />
              </ControlledInput>
              {getFormErrorMessage("ville")}
            </div>
          </div>
          <ControlledInput name="pays" control={methods.control}>
            <Dropdown
              className="w-full h-3rem align-items-center border-round border-1 text-black-alpha-90	 font-bold bg-white pl-3 md:p-4"
              placeholder="Choisissez le pays"
              options={pays}
              optionLabel="label"
              optionValue="value"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
            />
          </ControlledInput>
          {getFormErrorMessage("pays")}
        </InputContainer>
        <div className="flex relative w-12 lg:w-8   align-items-center justify-content-center lg:justify-content-end mt-4 mb-3">
        <MainButton type="submit" disabled={!isPhoneValid}>
          Soumettre
        </MainButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default InformationCompany;
