import React from "react";

const InputContainer = ({ show, label = null, children }) => {
  if (!Boolean(show)) return null;
  return (
    <>
      {label && (
        <h1 className="flex w-12 font-semibold  text-lg lg:text-3xl lg:w-8 mt-3 mb-2 ">
          {label}
        </h1>
      )}
      <div className="w-12 lg:w-8 ">{children}</div>
    </>
  );
};

export default InputContainer;
