import checkEmail from "../../checkEmail"
import checkIban from "../../checkIban"

const checkInfos = async() => {
    try {

        const email_contact = sessionStorage.getItem('email_contact')
        const mandat_infos = JSON.parse(sessionStorage.getItem('mandat_infos'))
        const num_buy = sessionStorage.getItem('num_buy')

        if(email_contact === null || mandat_infos === null) {
            return false
        }

        const email = checkEmail(email_contact)

        const ibanCheck = await checkIban(mandat_infos.iban)

        if(email === false) {
            return false
        }

        if(ibanCheck.valid === false) {
            return false
        }

        if(sessionStorage.getItem('config_send') === 'true') {
            return "envoyer"
        }

        return {success: true, data: { email_contact, mandat_infos, num_buy}}

    } catch(err) {
        throw err
    }
}

export default checkInfos