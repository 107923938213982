import "../assets/css/Error.css";
import logo from "../assets/logo/logo.png";
import errorImg from "../assets/images/error-img.svg";
import { Link } from "react-router-dom";

function Error404(props) {
  return (
    <>
      <div className="error-container">
        <img className="error-logo" src={logo} alt="" />
        <img className="error-img" src={errorImg} alt="" />
        <h3>Oops! Cette page est introuvable</h3>
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Link to="/">
          <button className="error-btn">&#8617; Revenir sur le site</button>
        </Link>
      </div>
    </>
  );
}

export default Error404;
