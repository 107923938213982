import { InfoIcon } from "../../assets/svgs";
import { editNumberTypeMessages } from "../../static";
const NumberAvailability = ({ isAvailable, numberType }) => {
  const numType =
    numberType?.indexOf("_") > -1 ? numberType.split("_")[0] : numberType;
  if (isAvailable === undefined) return null;
  const infoColor = isAvailable === true ? "green" : "#880100";

  return (
    <>
      <p
        className="flex align-items-center gap-2 font-italic text-sm md:text-base"
        style={{ color: infoColor }}
      >
        <InfoIcon />
        <p>
          {isAvailable === true ? (
            <>
              Le numéro sélectionné est <strong>disponible</strong>
            </>
          ) : (
            <>
              Le numéro sélectionné <strong>n’est pas disponible</strong>
            </>
          )}
        </p>
      </p>
      <p className="flex align-items-center gap-2 font-italic	primary-text">
        {editNumberTypeMessages[numType]}
      </p>
    </>
  );
};

export default NumberAvailability;
