import { createContext, useState } from "react";

export const StoreContext = createContext(null);

// eslint-disable-next-line
export default ({ children }) => {
  
  const [allForfaits, setForfaits] = useState([]);

  // Num
  const [num, setNum] = useState({zone: "", num: "", price: 0, type: ""});

  const [nextStep, setNextStep] = useState(false);

  const store = {
    forfaits: [allForfaits, setForfaits],
    nextStep: [nextStep, setNextStep],
    num: [num, setNum]
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
