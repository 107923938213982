import React from "react";
import { Button } from "primereact/button";

const MainButton = ({ label = "Suivant", disabled = false }) => {
  return (
    <Button
      disabled={disabled}
      label={label}
      className="w-full md:w-auto text-white px-6 flex align-items-center justify-content-center border-none"
      style={{
        backgroundColor: "#16C498",
      }}
      type="submit"
    />
  );
};

export default MainButton;
