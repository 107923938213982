import sendRequest from "../API";
import API_Routes from "../API/API_Routes";

const fetchNumbersByZone = async (zone, signal = null) => {
  const response = await sendRequest(
    API_Routes.fetchNumbersByZone,
    "POST",
    { n_zone: zone },
    signal
  );
  return response?.numero[0];
};

export { fetchNumbersByZone };
