import { useEffect, useState } from "react";
import "../assets/css/Success.css";
import logo from "../assets/logo/logo.svg";
import setting from "../assets/images/success/picto_setting.svg";
import picto_cross from "../assets/svgs/picto_cross.svg";

function Cancel() {
  let jsonFile = require(`../languages/fr-FR.json`);

  const [lang, setLang] = useState({
    title: jsonFile["cancel-title"],
    error: jsonFile["cancel-error"],
    infos: jsonFile["cancel-infos"],
    email: jsonFile["cancel-email"],
    back: jsonFile["cancel-back"],
  });

  useEffect(() => {
    let langCode = navigator.language;

    switch (langCode) {
      case "en-EN":
        let jsonFile = require(`../languages/en-EN.json`);

        setLang({
          ...lang,
          title: jsonFile["cancel-title"],

          error: jsonFile["cancel-error"],

          infos: jsonFile["cancel-infos"],
          email: jsonFile["cancel-email"],

          back: jsonFile["cancel-back"],
        });
        break;

      default:
        break;
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="success-commande">
      <div className="div_img">
        <img width="400rem" height="auto" src={logo} alt="logo-colortelecom" />
      </div>
      <div className="div_paiement_reussi flexed">
        <img
          src={picto_cross}
          alt="checked-colortelecom"
          style={{ height: "50px" }}
        />
        <div className="paiement_reussi" style={{ color: "red" }}>
          <p>{lang.title}</p>
        </div>
      </div>
      <div className="div_mrc_confiance">
        <p className="mrc_confiance" style={{ color: "red" }}>
          {lang.error}
        </p>
      </div>
      <div className="div_envoi_email">
        <p className="envoi_email">{lang.infos}</p>
      </div>
      <div className="div_adresse_email">
        <div className="adresse_email">
          <a className="mail" href="mailto:support@standardenligne.fr">
            {lang.email}
          </a>
        </div>
      </div>
      <div className="div_btn_config">
        <a className="btn btn_config flexed" href="forfait" rel="noreferrer">
          <img src={setting} alt="checked-colortelecom" />
          <span className="btn_text">{lang.back}</span>
        </a>
      </div>
    </div>
  );
}

export default Cancel;
