const extractNumbersFromString = (str) => {
  const regex = /\d+/g;
  const numbers = str.match(regex);
  return numbers ? numbers.join("") : "";
};

const offersToDropdownGroup = (offers) => {
  // Remove Unlimitted offers
  offers["forfaitM"] = offers["forfaitM"].filter(
    (item) => item.nombre_heure !== "Illimité"
  );
  offers["forfaitA"] = offers["forfaitA"].filter(
    (item) => item.nombre_heure !== "Illimité"
  );
  return [
    {
      label: "Mensuel",
      items: offers["forfaitM"].map((item) => {
        return {
          label: item.nom,
          value: item.id,
          prix: item.prix,
          nombre_heure: item.nombre_heure,
        };
      }),
    },
    {
      label: "Annuel",
      items: offers["forfaitA"].map((item) => {
        return {
          label: item.nom,
          value: item.id,
          prix: item.prix,
          nombre_heure: item.nombre_heure,
        };
      }),
    },
  ];
};

export { extractNumbersFromString, offersToDropdownGroup };
