import React, { useState } from "react";
import side_img from "../assets/images/side_img.png";
import { CheckIcon } from "../assets/svgs";

const SidePanel = ({ title, image, visibility, content = [] }) => {
  if (visibility === false) return null;
  return (
    <>
      <img src={image} alt="" className="aside-image" />
      <div className="aside-content">
        <h1 className="title">{title}</h1>
        <ul className="description">
          {content.map((item, index) => (
            <li key={index}>
              <CheckIcon />
              {item}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SidePanel;
